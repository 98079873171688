const PATHS = {
  root: "/",
  home: "/home",
  law: "/ley/:id",
  news: "/noticias",
  lawSearch: "/buscar/ley",
  legislator: "/legislador/:id",
  legislatorProjects: "/legislador/:id/proyectos",
  legislatorSearch: "/buscar/legislador",
  legislatorVotes: "/legislador/:id/votos",
  party: "/partido/:id",
  partyLegislators: "/partido/:id/legisladores",
  partyProjects: "/partido/:id/proyectos",
  partySearch: "/buscar/partido",
  partyVotes: "/partido/:id/votos",
  prediction: "/prediccion",
  predictionResult: "/prediccion/resultado",
  predictionVoting: "/prediccion/votacion",
  project: "/proyecto/:id",
  projectSearch: "/buscar/proyecto",
  projectVoting: "/proyecto/:id/votacion",
  sources: "/fuentes",
  notFound: "404",
};

export default PATHS;
